import Vue from "vue";
import Router from "vue-router";
const je = require("json-encrypt");

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

// Views - Components

const Forms = () => import("@/views/base/Forms");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Register = () => import("@/views/pages/Register");

const userGuard = (to, from, next) => {
  let user = window.localStorage.getItem("user");
  let user_permissions = window.localStorage.getItem("user_permissions");

  if (user == null || user == undefined) {
    next("/");
  } else {
    user = JSON.parse(JSON.parse(je.decrypt(user)));
    if (user.api_token.length != 60) {
      next("/");
    } else {
      let isAuthenticated = false;
      if (user_permissions == null || user_permissions == undefined) {
        next({ name: "Page404" });
      } else {
        user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
        if (user_permissions.indexOf(to.name) > -1) isAuthenticated = true;
      }
      if (!isAuthenticated) next({ name: "Page404" });
      else next();
    }
  }
};

Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/",
      name: "Login",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "/",
          name: "LoginPage",
          component: Login,
        },
      ],
    },
    {
      path: "/inicio",
      redirect: "/inicio/inicio",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "inicio",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
      ],
    },

    {
      path: "/pedido",
      redirect: "/pedido/listar",
      name: "Order",
      component: TheContainer,
      children: [
        
        {
          path: "mesas",
          name: "OrderTable",
          component: () => import("@/views/order/Table"),
        },
        {
          path: "nuevo/:id_order",
          name: "OrderAdd",
          component: () => import("@/views/order/Add"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "pagar/:id_order",
          name: "OrderComplete",
          component: () => import("@/views/order/Complete"),
          props: true,
        },




        {
          path: "listar",
          name: "OrderList",
          component: () => import("@/views/order/List"),
          beforeEnter: userGuard,
        },
        // {
        //   path: "nuevo",
        //   name: "OrderAdd",
        //   component: () => import("@/views/order/Add"),
        //   beforeEnter: userGuard,
        // },
        {
          path: "editar/:id_order",
          name: "OrderEdit",
          component: () => import("@/views/order/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_order",
          name: "OrderView",
          component: () => import("@/views/order/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/usuario",
      redirect: "/usuario/listar",
      name: "Usuario",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "UserList",
          component: () => import("@/views/user/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "UserAdd",
          component: () => import("@/views/user/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_user",
          name: "UserEdit",
          component: () => import("@/views/user/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_user",
          name: "UserView",
          component: () => import("@/views/user/View"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "profile",
          name: "UserProfile",
          component: () => import("@/views/pages/Profile"),
          props: true,
        },
      ],
    },

    {
      path: "/tipo-usuario",
      redirect: "/tipo-usuario/listar",
      name: "TipoUsuario",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "UserTypeList",
          component: () => import("@/views/usertype/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "UserTypeAdd",
          component: () => import("@/views/usertype/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_user_type",
          name: "UserTypeEdit",
          component: () => import("@/views/usertype/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_user_type",
          name: "UserTypeView",
          component: () => import("@/views/usertype/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/moso",
      redirect: "/moso/listar",
      name: "Moso",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "MosoList",
          component: () => import("@/views/moso/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "MosoAdd",
          component: () => import("@/views/moso/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_moso",
          name: "MosoEdit",
          component: () => import("@/views/moso/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_moso",
          name: "MosoView",
          component: () => import("@/views/moso/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/cliente",
      redirect: "/cliente/listar",
      name: "Cliente",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ClientList",
          component: () => import("@/views/client/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ClientAdd",
          component: () => import("@/views/client/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_client",
          name: "ClientEdit",
          component: () => import("@/views/client/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_client",
          name: "ClientView",
          component: () => import("@/views/client/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/proveedor",
      redirect: "/proveedor/listar",
      name: "Proveedor",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ProviderList",
          component: () => import("@/views/provider/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ProviderAdd",
          component: () => import("@/views/provider/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_provider",
          name: "ProviderEdit",
          component: () => import("@/views/provider/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_provider",
          name: "ProviderView",
          component: () => import("@/views/provider/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },



    {
      path: "/caja",
      redirect: "/caja/listar",
      name: "Box",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "BoxList",
          component: () => import("@/views/box/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "BoxAdd",
          component: () => import("@/views/box/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_box",
          name: "BoxEdit",
          component: () => import("@/views/box/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_box",
          name: "BoxView",
          component: () => import("@/views/box/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/ingreso-extraordinario",
      redirect: "/ingreso-extraordinario/listar",
      name: "ExtraordinaryIncome",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ExtraordinaryIncomeList",
          component: () => import("@/views/extraordinary-income/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ExtraordinaryIncomeAdd",
          component: () => import("@/views/extraordinary-income/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_extraordinary_income",
          name: "ExtraordinaryIncomeEdit",
          component: () => import("@/views/extraordinary-income/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_extraordinary_income",
          name: "ExtraordinaryIncomeView",
          component: () => import("@/views/extraordinary-income/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/egreso-extraordinario",
      redirect: "/egreso-extraordinario/listar",
      name: "ExtraordinaryExpense",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ExtraordinaryExpenseList",
          component: () => import("@/views/extraordinary-expense/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ExtraordinaryExpenseAdd",
          component: () => import("@/views/extraordinary-expense/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_extraordinary_expense",
          name: "ExtraordinaryExpenseEdit",
          component: () => import("@/views/extraordinary-expense/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_extraordinary_expense",
          name: "ExtraordinaryExpenseView",
          component: () => import("@/views/extraordinary-expense/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/empresa",
      redirect: "/empresa/editar",
      name: "Eempresa",
      component: TheContainer,
      children: [
        {
          path: "editar",
          name: "BusinessEdit",
          component: () => import("@/views/business/Edit"),
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/categoria-producto",
      redirect: "/categoria-producto/listar",
      name: "CategoryProduct",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "CategoryProductList",
          component: () => import("@/views/category-product/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "CategoryProductAdd",
          component: () => import("@/views/category-product/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_category_product",
          name: "CategoryProductEdit",
          component: () => import("@/views/category-product/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_category_product",
          name: "CategoryProductView",
          component: () => import("@/views/category-product/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/producto",
      redirect: "/producto/listar",
      name: "Product",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ProductList",
          component: () => import("@/views/product/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ProductAdd",
          component: () => import("@/views/product/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "importar",
          name: "ProductImport",
          component: () => import("@/views/product/Import"),
        },
        {
          path: "editar/:id_product",
          name: "ProductEdit",
          component: () => import("@/views/product/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_product",
          name: "ProductView",
          component: () => import("@/views/product/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/categoria-carta",
      redirect: "/categoria-carta/listar",
      name: "CategoryLetter",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "CategoryLetterList",
          component: () => import("@/views/category-letter/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "CategoryLetterAdd",
          component: () => import("@/views/category-letter/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_category_letter",
          name: "CategoryLetterEdit",
          component: () => import("@/views/category-letter/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_category_letter",
          name: "CategoryLetterView",
          component: () => import("@/views/category-letter/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/carta",
      redirect: "/carta/listar",
      name: "Letter",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "LetterList",
          component: () => import("@/views/letter/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "LetterAdd",
          component: () => import("@/views/letter/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_letter",
          name: "LetterEdit",
          component: () => import("@/views/letter/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_letter",
          name: "LetterView",
          component: () => import("@/views/letter/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/abastecimiento",
      redirect: "/abastecimiento/listar",
      name: "Catering",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "CateringList",
          component: () => import("@/views/catering/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "CateringAdd",
          component: () => import("@/views/catering/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_catering",
          name: "CateringEdit",
          component: () => import("@/views/catering/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_catering",
          name: "CateringView",
          component: () => import("@/views/catering/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/sala",
      redirect: "/sala/listar",
      name: "Room",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "RoomList",
          component: () => import("@/views/room/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "RoomAdd",
          component: () => import("@/views/room/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_room",
          name: "RoomEdit",
          component: () => import("@/views/room/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_room",
          name: "RoomView",
          component: () => import("@/views/room/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/mesa",
      redirect: "/mesa/listar",
      name: "Table",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "TableList",
          component: () => import("@/views/table/List"),
          beforeEnter: userGuard,
        },
      ],
    },



    {
      path: "/comprobante",
      redirect: "/comprobante/listar",
      name: "Voucher",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "VoucherList",
          component: () => import("@/views/voucher/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "VoucherAdd",
          component: () => import("@/views/voucher/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_voucher",
          name: "VoucherEdit",
          component: () => import("@/views/voucher/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_voucher",
          name: "VoucherView",
          component: () => import("@/views/voucher/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/almacen",
      redirect: "/almacen/listar",
      name: "Warehouse",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "WarehouseList",
          component: () => import("@/views/warehouse/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "WarehouseAdd",
          component: () => import("@/views/warehouse/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_warehouse",
          name: "WarehouseEdit",
          component: () => import("@/views/warehouse/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_warehouse",
          name: "WarehouseView",
          component: () => import("@/views/warehouse/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/compra",
      redirect: "/compra/listar",
      name: "Shopping",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ShoppingList",
          component: () => import("@/views/shopping/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ShoppingAdd",
          component: () => import("@/views/shopping/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_shopping",
          name: "ShoppingEdit",
          component: () => import("@/views/shopping/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_shopping",
          name: "ShoppingView",
          component: () => import("@/views/shopping/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/entrada",
      redirect: "/entrada/listar",
      name: "Input",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "InputList",
          component: () => import("@/views/input/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "InputAdd",
          component: () => import("@/views/input/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_input",
          name: "InputEdit",
          component: () => import("@/views/input/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_input",
          name: "InputView",
          component: () => import("@/views/input/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/salida",
      redirect: "/salida/listar",
      name: "Output",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "OutputList",
          component: () => import("@/views/output/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "OutputAdd",
          component: () => import("@/views/output/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_output",
          name: "OutputEdit",
          component: () => import("@/views/output/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_output",
          name: "OutputView",
          component: () => import("@/views/output/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/kardex",
      redirect: "/kardex/stock-general",
      name: "Kardex",
      component: TheContainer,
      children: [
        {
          path: "stock-general",
          name: "StockGeneralList",
          component: () => import("@/views/kardex/StockGeneral"),
          beforeEnter: userGuard,
        },
        {
          path: "fisico",
          name: "KardexPhysicalList",
          component: () => import("@/views/kardex/KardexPhysical"),
          beforeEnter: userGuard,
        },
      ],
    },





    {
      path: "/cuentas-por-pagar",
      redirect: "/cuentas-por-pagar/listar",
      name: "AccountPay",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "AccountPayList",
          component: () => import("@/views/account-pay/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "AccountPayAdd",
          component: () => import("@/views/account-pay/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_account_pay",
          name: "AccountPayEdit",
          component: () => import("@/views/account-pay/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_account_pay",
          name: "AccountPayView",
          component: () => import("@/views/account-pay/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },


    {
      path: "/cuentas-por-cobrar",
      redirect: "/cuentas-por-cobrar/listar",
      name: "AccountReceivable",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "AccountReceivableList",
          component: () => import("@/views/account-receivable/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "AccountReceivableAdd",
          component: () => import("@/views/account-receivable/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_account_receivable",
          name: "AccountReceivableEdit",
          component: () => import("@/views/account-receivable/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_account_receivable",
          name: "AccountReceivableView",
          component: () => import("@/views/account-receivable/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/cobro",
      redirect: "/cobro/listar",
      name: "Charge",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ChargeList",
          component: () => import("@/views/charge/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ChargeAdd",
          component: () => import("@/views/charge/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_charge",
          name: "ChargeEdit",
          component: () => import("@/views/charge/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_charge",
          name: "ChargeView",
          component: () => import("@/views/charge/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },



    {
      path: "/reserva",
      redirect: "/reserva/listar",
      name: "Reservation",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ReservationList",
          component: () => import("@/views/reservation/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ReservationAdd",
          component: () => import("@/views/reservation/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_reservation",
          name: "ReservationEdit",
          component: () => import("@/views/reservation/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_reservation",
          name: "ReservationView",
          component: () => import("@/views/reservation/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },

    {
      path: "/tipo-de-cambio",
      redirect: "/tipo-de-cambio/listar",
      name: "ExchangeRate",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "ExchangeRateList",
          component: () => import("@/views/exchange-rate/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "ExchangeRateAdd",
          component: () => import("@/views/exchange-rate/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_exchange_rate",
          name: "ExchangeRateEdit",
          component: () => import("@/views/exchange-rate/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_exchange_rate",
          name: "ExchangeRateView",
          component: () => import("@/views/exchange-rate/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },



    {
      path: "/delivery",
      redirect: "/delivery/listar",
      name: "Delivery",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "DeliveryList",
          component: () => import("@/views/delivery/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo",
          name: "DeliveryAdd",
          component: () => import("@/views/delivery/Add"),
          beforeEnter: userGuard,
        },
        {
          path: "editar/:id_delivery",
          name: "DeliveryEdit",
          component: () => import("@/views/delivery/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_delivery",
          name: "DeliveryView",
          component: () => import("@/views/delivery/View"),
          props: true,
          beforeEnter: userGuard,
        },
      ],
    },




    {
      path: "/reporte",
      redirect: "/reporte/pedido",
      name: "Report",
      component: TheContainer,
      children: [
        {
          path: "pedido",
          name: "ReportOrderList",
          component: () => import("@/views/report/Order"),
          beforeEnter: userGuard,
        },
        {
          path: "delivery",
          name: "ReportDeliveryList",
          component: () => import("@/views/report/Delivery"),
          beforeEnter: userGuard,
        },
        {
          path: "caja",
          name: "ReportBoxList",
          component: () => import("@/views/report/Box"),
          beforeEnter: userGuard,
        },
        {
          path: "platos",
          name: "ReportDishList",
          component: () => import("@/views/report/Dish"),
          beforeEnter: userGuard,
        },
        {
          path: "platos-anulados",
          name: "ReportDishCanceledList",
          component: () => import("@/views/report/DishCanceled"),
          beforeEnter: userGuard,
        },
        {
          path: "platos-valorado",
          name: "ReportDishValuedList",
          component: () => import("@/views/report/DishValued"),
          beforeEnter: userGuard,
        },
        
        {
          path: "precios-modificados",
          name: "ReportPriceModificationList",
          component: () => import("@/views/report/PriceModification"),
          beforeEnter: userGuard,
        },

        {
          path: "pax",
          name: "ReportPaxList",
          component: () => import("@/views/report/Pax"),
          beforeEnter: userGuard,
        },
        
      ],
    },


    {
      path: "/venta",
      redirect: "/venta/listar",
      name: "Sale",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "SaleList",
          component: () => import("@/views/sale/List"),
          beforeEnter: userGuard,
        },
        {
          path: "nuevo-pedido/:id_order",
          name: "SaleAddOrder",
          component: () => import("@/views/sale/AddOrder"),
          props: true,
        },
        {
          path: "nuevo-delivery/:id_delivery",
          name: "SaleAddDelivery",
          component: () => import("@/views/sale/AddDelivery"),
          props: true,
        },
        {
          path: "canjear/:id_quotation",
          name: "SaleTrade",
          component: () => import("@/views/sale/Trade"),
          props: true,
        },
        {
          path: "editar/:id_sale",
          name: "SaleEdit",
          component: () => import("@/views/sale/Edit"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "ver/:id_sale",
          name: "SaleView",
          component: () => import("@/views/sale/View"),
          props: true,
          beforeEnter: userGuard,
        },
        {
          path: "nota-credito-debito/:id_sale",
          name: "SaleNotaCreditoDebito",
          component: () => import("@/views/sale/AddNotaCreditoDebito"),
          props: true,
        },
      ],
    },



    {
      path: "/productos-anulados",
      redirect: "/productos-anulados/listar",
      name: "LetterCanceled",
      component: TheContainer,
      children: [
        {
          path: "listar",
          name: "LetterCanceledList",
          component: () => import("@/views/letter-canceled/List"),
          beforeEnter: userGuard,
        },
      ],
    },














  ];
}
